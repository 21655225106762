import * as React from "react"
import styled, {keyframes} from "styled-components"
import "@fontsource/merriweather"
import "@fontsource/staatliches"
import {Link} from "gatsby";

// styles
const pageStyles = {
    fontFamily: "Roboto, sans-serif, serif",
    width: "auto",
    height: "auto",
    minHeight: "100vh",
    padding: "auto",
    display: "flex",
}
const ContentWrapper = styled.div`
  max-width: 768px;
  margin: auto;
  padding: 30px;
  transform: skewY(-4.5deg) translateZ(100px);
  color: #b0b0b0;
  font-size: 1.5em;
  box-shadow: 8px 8px 0 8px #ffffff00,
    -8px -8px 0 8px #ff008c00;
  border-radius: 16px;
  transition: all 0.5s;
  @media (min-width: 768px) {

    :hover {
      box-shadow: 8px 8px 0 8px #ffffff,
        -8px -8px 0 8px #ff008c;
      background-color: #181818cc;
    }

    :hover h1 {
      color: #ddd;
      text-shadow: 0 0 8px #ddd,
      16px 16px 0 #ff008c;
    }

    :hover h2 {
      color: #ddd;
    }

    :hover p {
      color: #ddd;
    }

    :hover span {
      color: #ff008c;
      //background-color: #ddd;
    }

    :hover a {
      color: #ff008c
    }

    span {
      transition: color 0.5s, background-color 0.5s;
    }

    a {
      transition: color 0.25s;
    }
  }
`
const HeaderAnimation = keyframes`
  0% {
    background-position: 0% 0%
  }
  100% {
    background-position: 0% -400%
  }
`
const Header = styled.h1`
  font-family: Staatliches, sans-serif;
  font-size: 16rem;
  line-height: 0.8em;
  transition: all 0.5s;
  text-shadow: 0 0 8px transparent;
  @media (max-width: 600px) {
    font-size: 32vw;
  }
  margin: 0 0 0 -0.025em;
  color: transparent;
  background: linear-gradient(0deg, #00fff3, #ff008c, #00fff3);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 400%;
  animation: ${HeaderAnimation} 32s linear infinite;
`
const ContentParagraph = styled.p`
  font-family: Merriweather, serif;
  line-height: 1.2;
  font-size: 1em;
  transition: color 1s;
`
const LinkWrapper = styled.div`
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #ffffff;
  font-family: Merriweather, serif;
  position: relative;
  z-index: 1;

  ::after {
    content: "";
    display: block;
    position: absolute;
    background: #ff008c;
    height: 100%;
    transform: scaleY(0);
    transform-origin: bottom;
    bottom: 0;
    border-radius: 4px;
    left: -1px;
    right: -1px;
    margin: 0 auto;
    z-index: -1;
  }

  :hover ::after {
    max-height: 100%;
    animation: scaleY-easeOutElastic 0.75s;
    animation-fill-mode: forwards;
  }

  :hover {
    color: white !important;
  }
`
const FadeInWrapper = styled.div`
  animation: 0.25s ease-out 0s 1 FadeInDown;
`

// markup
const IndexPage = () => {
    return (
        <main style={pageStyles}>
            <ContentWrapper>
                <FadeInWrapper>
                    <title>SH Liu | rarakasm</title>
                    <Header>SHI<br/>HONG<br/>LIU</Header>
                    <ContentParagraph>
                        love making stuff. love breaking stuff even more. love music.
                    </ContentParagraph>
                    <LinkWrapper>
                        <span><StyledLink to="/about">about</StyledLink> | <StyledLink
                            to="/portfolio">portfolio</StyledLink> | <StyledLink to="/blog">blog</StyledLink></span>
                    </LinkWrapper>
                </FadeInWrapper>
            </ContentWrapper>
        </main>
    )
}

export default IndexPage
